import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { LoadingController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { BehaviorSubject, debounceTime, distinctUntilChanged, filter, map, merge, of, shareReplay, switchMap, tap } from 'rxjs';
import { SynapsMediaApiService } from 'src/app/synaps-media-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-site',
  templateUrl: './add-site.component.html',
  styleUrls: ['./add-site.component.scss'],
})
export class AddSiteComponent  implements OnInit {

  @Input()
  onboarding = false;

  @Input()
  trial = false;

  username = new FormControl('');
  serviceDomain = environment.serviceDomain;

  valueChange$ = this.username.valueChanges.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    shareReplay(),
  );

  invalid$ = this.valueChange$.pipe(
    map(() => this.username.invalid),
  );

  availability$ = this.valueChange$.pipe(
    filter(() => this.username.valid),
    filter((username) => username.length > 0),
    tap((username) => console.log('username', username)),
    switchMap((username) => this.api.checkServiceNameAvailability(username)),
    shareReplay(),
  );

  inprogress$ = merge(
    of(false),
    this.valueChange$.pipe(map(() => this.username.valid)),
    this.availability$.pipe(map(() => false)),
  ).pipe(
    // debounceTime(300),
    tap((inprogress) => console.log('inprogress', inprogress))
  );

  available$ = merge(
    of(false),
    this.username.valueChanges.pipe(map(() => false)),
    this.availability$,
  ).pipe(
    debounceTime(100),
    tap((inprogress) => console.log('available', inprogress))
  );

  unavailable$ = merge(
    of(false),
    this.username.valueChanges.pipe(map(() => false)),
    this.availability$.pipe(map((available) => !available)),
  ).pipe(
    debounceTime(100),
    tap((inprogress) => console.log('unavailable', inprogress))
  );

  stopContinue$ = merge(
    of(true),
    this.username.valueChanges.pipe(map(() => true)),
    this.availability$.pipe(map((available) => !available)),
  ).pipe(
    debounceTime(100),
    tap((inprogress) => console.log('available', inprogress))
  );

  applicationsStatus$ = new BehaviorSubject(false);

  ghostServiceName = new FormControl('');

  domain = new FormControl('synaps.space');

  constructor(private api: SynapsMediaApiService, private store: Store, private loadingCtrl: LoadingController) {}

  async reserveDomain() {
    const loading = await this.loadingCtrl.create({
      message: 'Please wait...',
    });

    loading.present();

    this.api.createSite(this.username.value)
      .subscribe({
        next: ({ site, paymentUrl }) => {
        console.log('created Site', site);
        location.href = paymentUrl;
      },
      error: (error) => {
        loading.dismiss();
      }});
  }
  ngOnInit() {}

}
