import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-view',
  templateUrl: './modal-view.component.html',
  styleUrls: ['./modal-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalViewComponent {

  constructor() { }

}
