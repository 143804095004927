<ion-content scrollY="false">
  <ion-grid>
    <ion-row class="modal-view-container-row">
      <ion-col>
        <div class="modal-view-container">
          <ng-content></ng-content>
        </div>
      </ion-col>
      <ion-col class="auth-side">
        <ng-content select="[side]"></ng-content>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
