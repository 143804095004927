<ion-list inset style="max-width: 420px;" lines="full">
  <ion-list-header *ngIf="onboarding">
    <ion-label>Let's finish this...</ion-label>
  </ion-list-header>
  <ion-item>
    <ion-label>How would you like to have an address?</ion-label>
  </ion-item>
  <ion-item>
    <ion-input [formControl]="username" pattern="[A-Za-z0-9](?:[A-Za-z0-9\-]{3,61}[A-Za-z0-9])" maxlength="63" placeholder="namesurname"></ion-input>.{{ serviceDomain }}
  </ion-item>
</ion-list>
<ion-note class="list-note ion-margin">You will able to connect your own domain after creating your website.</ion-note>

<ion-list inset *ngIf="invalid$ | async">
  <ion-item>
    <ion-label>
      This address is not valid. Please check the rules:<br><br>

      * Address can only contains latin letters, numbers and dash(-).<br>
      * You can not use dash(-) at the beginning or at the end.
    </ion-label>
  </ion-item>
</ion-list>

<ion-list inset *ngIf="inprogress$ | async">
  <ion-item>
    <ion-label>Checking availability...</ion-label>
    <ion-spinner></ion-spinner>
  </ion-item>
</ion-list>

<ion-list inset style="text-align: center;" *ngIf="available$ | async">
  <ion-item>
    <ion-label>Your address will be:</ion-label>
  </ion-item>
  <ion-item>
    <ion-icon slot="start" name="checkmark-circle" color="success"></ion-icon>
    <ion-label><h1>{{ username.value }}.{{ serviceDomain }}</h1></ion-label>
  </ion-item>
</ion-list>

<ion-list inset style="text-align: center;" *ngIf="unavailable$ | async">
  <ion-item>
    <ion-icon slot="start" name="close-circle" color="danger"></ion-icon>
    <ion-label>This address is not available.</ion-label>
  </ion-item>
</ion-list>

<ion-list inset>
  <ion-item button color="primary" (click)="reserveDomain()" [disabled]="stopContinue$ | async">
    <ion-label *ngIf="trial">Start free trial</ion-label>
    <ion-label *ngIf="!trial">Go to payment</ion-label>
  </ion-item>
</ion-list>
